import React, { useEffect } from "react";
import { connect } from "react-redux";
import _isEmpty from "lodash/isEmpty";
import { setCheckoutData } from "@actions/checkout";
import { createModal } from "@actions/ui";
import { RedirectModal } from "@components/global/timer/RedirectModal";
import {
  PaymentContainer,
  currencyNormalizerCreator,
  createFixedFloatNormalizer,
} from "tf-checkout-react";
import { setTfCheckoutReactConfigs } from "@utils/utils";
import { navigate } from "gatsby";
import { removeModal } from "../../../actions/ui";

setTfCheckoutReactConfigs();

const Checkout = (props) => {
  const {
    checkoutData,
    setCheckoutData,
    createModal,
    browser,
    showLoader,
    hash,
    removeModal
  } = props;

  const stripeStyleOptions = {
    style: {
      base: {
        backgroundColor: "transparent",
        fontSize: "18px",
        color: "#858585",
        fontWeight: "600",
        fontFamily: "Inter",
        letterSpacing: "1px",
        ":-webkit-autofill": {
          backgroundColor: "transparent",
          color: "#000",
        },
        "::placeholder": {
          color: "#858585",
        },
      },
      invalid: {
        color: "#E53935",
      },
    },
  };

  const paymentFields = [
    {
      label: "Event",
      id: "product_name",
      class: "field-underline",
    },
    {
      label: "Ticket Type",
      id: "ticketType",
      class: "",
    },
    {
      label: "Number of Tickets",
      id: "quantity",
      class: "",
    },
    {
      label: "Price (per ticket, incl. fees)",
      id: "price",
      class: "field-underline",
      normalizer: (value, currency) =>
        currencyNormalizerCreator(
          createFixedFloatNormalizer(2)(parseFloat(value)),
          currency
        ),
    },
    {
      label: "Add-ons",
      id: "add_ons",
      class: "",
    },
    {
      label: "Total (incl. fees, card processing and taxes)",
      id: "total",
      class: "",
      normalizer: (value, currency) =>
        currencyNormalizerCreator(
          createFixedFloatNormalizer(2)(parseFloat(value)),
          currency
        ),
    },
  ];

  useEffect(() => {
    if (_isEmpty(checkoutData)) {
      setCheckoutData({ hash });
    }
  }, []);

  if (_isEmpty(checkoutData) || showLoader) {
    return null;
  }

  const params = new URLSearchParams(window.location.search);
  const slug = params.get("slug")

  return (
    <PaymentContainer
      checkoutData={checkoutData}
      paymentFields={paymentFields}
      disableZipSection={true}
      handlePayment={() => {
        navigate(`/checkout/complete/${hash}`);
      }}
      stripeCardOptions={stripeStyleOptions}
      enableTimer={true}
      onCountdownFinish={() => {
        const modal = {
          id: "error-modal",
          Component: () => (
            <RedirectModal browser={browser} onClickOk={() => {
              navigate(`/events/${slug}`)
              removeModal("error-modal")
            }} />
          ),
          shouldCloseOnOverlayClick: false,
          isCloseBtn: false,
        };
        createModal(modal);
      }}
      paymentButtonText="Confirm Payment -"
    />
  );
};

const mapStateToProps = (state) => {
  return {
    checkoutData: state.checkout.checkoutData,
    browser: state.browser,
    showLoader: state.ui.showLoader,
    hash: window.location.pathname.split("/")[2],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCheckoutData: (data) => {
      dispatch(setCheckoutData(data));
    },
    createModal: (modal, close_others) => {
      dispatch(createModal(modal, close_others));
    },
    removeModal: (id) => {
      dispatch(removeModal(id));
    },
  };
};

const CheckoutRedux = connect(mapStateToProps, mapDispatchToProps)(Checkout);

export default CheckoutRedux;
